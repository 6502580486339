
import { WorkOrderFormDesignEntityModel, WorkOrderFormDesignQueryModel } from '@common-src/entity-model/work-order-form-design-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, put, del } from './request';

const URL_PATH = `${WORKORDER_BASE_REQUEST_PATH}/formdesign/field`;

const QUERY_URL = `${WORKORDER_BASE_REQUEST_PATH}/workorderActiform`;

export default class WorkOrderFormService implements ICRUDQ<WorkOrderFormDesignEntityModel, WorkOrderFormDesignQueryModel> {
    private formId:string='';

    constructor(formId: string) {
        this.formId = formId;
    }

    async create(model: WorkOrderFormDesignEntityModel):Promise<WorkOrderFormDesignEntityModel> {
        const url = `${URL_PATH}/add`;
        const params = Object.assign(model.toService(), { formId: this.formId, system: false });
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<WorkOrderFormDesignEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new WorkOrderFormDesignEntityModel().toModel(res);
    }

    async update(model: WorkOrderFormDesignEntityModel):Promise<WorkOrderFormDesignEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: WorkOrderFormDesignEntityModel):Promise<WorkOrderFormDesignEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: WorkOrderFormDesignQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${QUERY_URL}/${this.formId}/details`;

        // const params = Object.assign({ page, limit }, query?.toService());
        const res = await get(url);
        // const res = await post(url, {});
        return _.map(res.fields, item => item = new WorkOrderFormDesignEntityModel().toModel(item));
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new WorkOrderTypeEntityModel().toModel(item));
    }

    // async changeOrder(model: WorkOrderFormDesignEntityModel, type:string):Promise<any> {
    //     const url = `${URL_PATH}/index/${model.id}/${type}`;
    //     const res = await post(url);
    //     return res;
    // }

    async saveUI(formId:string, fields:Array<any>):Promise<any> {
        const url = `${URL_PATH}/${formId}/typography`;
        const res = await post(url, fields);
        return res;
    }
}
